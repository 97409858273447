/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    // <!-- Main Sidebar Container -->
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* <!-- Brand Logo --> */}
      <Link to="/" className="brand-link">
        <img
          src="../../dist/img/AdminLTELogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
        <span className="brand-text font-weight-light">Dashboard</span>
      </Link>

      {/* <!-- Sidebar --> */}
      <div className="sidebar">
        {/* <!-- Sidebar user (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="../../dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="/" className="d-block">
              Administrator
            </Link>
          </div>
        </div>

        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <Link to="/product" className="nav-link">
                <i className="nav-icon fas fa-table"></i>
                <p>Sản phẩm</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/productCategory" className="nav-link">
                <i className="nav-icon fas fa-archive"></i>
                <p>Danh mục sản phẩm</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/media" className="nav-link">
                <i className="nav-icon fas fa-images"></i>
                <p>Media</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/slide" className="nav-link">
                <i className="nav-icon fas fa-book"></i>
                <p>Slide</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className="nav-link">
                <i className="nav-icon fas fa-camera-retro"></i>
                <p>Bộ sưu tập</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/feedback" className="nav-link">
                <i className="nav-icon fas fa-reply"></i>
                <p>Feedback</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/tag" className="nav-link">
                <i className="nav-icon fas fa-tag"></i>
                <p>Tag</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/footer" className="nav-link">
                <i className="nav-icon fas fa-clipboard-list"></i>
                <p>Footer</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                <i className="nav-icon 	fas fa-info-circle"></i>
                <p>Giới thiệu</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/visitorStatistic" className="nav-link">
                <i className="nav-icon 	fas fa-chart-bar"></i>
                <p>Thống kê truy cập</p>
              </Link>
            </li>
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
};

export default Sidebar;
