import React, { useEffect } from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import { Constant, Common } from './utils';
import { Login, Register } from './pages';
import Topbar from 'topbar';

const PrivateRoute = () => {
  return Common.isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};
const Logout = () => {
  Cookies.remove(Constant.TOKEN);
  return <Navigate to="/login" />;
};

const App = () => {
  useEffect(() => {
    Topbar.config({ autoRun: true });
  }, []);
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route exact path="/" element={<PrivateRoute />}>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return (
              <Route
                key={index}
                {...rest}
                element={<Layout>{element}</Layout>}
              />
            );
          })}
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
