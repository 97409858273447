/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { ActionButton } from '../../components';
import { Agent, Common, Constant } from '../../utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Topbar from 'topbar';
import './Feedback.css';

const DEFAULT_INPUT = {
  name: '',
  phone: '',
  email: '',
  content: '',
};
const Feedback = () => {
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [inputModal, setInputModal] = useState(DEFAULT_INPUT);
  const [modeModal, setModeModal] = useState(Constant.ADD);
  const [pageInfo, setPageInfo] = useState(Constant.DEFAULT_PAGE_INFO);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageInfo.pageIndex]);

  const loadData = () => {
    Topbar.show();
    Agent.Feedback.getAllPaging(pageInfo.pageIndex, pageInfo.pageSize)
      .then((res) => {
        setData(res.items);
        setPageInfo((prevState) => ({
          ...prevState,
          totalItems: res.totalItems,
          pageCount: res.pageCount,
        }));
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => Topbar.hide());
  };

  const handleSetMode = (mode, item) => {
    setInputModal(item);
    if (mode === Constant.DELETE) {
      toggleConfirm();
    } else {
      setModeModal(mode);
      toggle();
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'status') {
      setInputModal((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setInputModal((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePageChange = (e) => {
    console.log(e);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    setPageInfo((prevState) => ({
      ...prevState,
      pageIndex: e.selected,
    }));
  };

  return (
    <>
      {/* // <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Feedback</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Trang chủ</Link>
                </li>
                <li className="breadcrumb-item active">Feedback</li>
              </ol>
            </div>
          </div>
        </div>
        {/* <!-- /.container-fluid --> */}
      </section>

      {/* // <!-- Main content --> */}
      <section className="content">
        <Card className="my-2">
          <CardHeader></CardHeader>
          <CardBody>
            <Table hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tên</th>
                  <th>Điện thoại</th>
                  <th>Email</th>
                  <th>Ngày tạo</th>
                  <th style={{ textAlign: 'center', width: '10%' }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1 + pageInfo.pageIndex * 10}</th>
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td>{item.email}</td>
                      <td>{Common.ToShortDateStr(item.createdAt)}</td>
                      <td>
                        <ActionButton
                          canEdit={false}
                          canDelete={false}
                          item={item}
                          setMode={handleSetMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <div style={{ float: 'right' }}>
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageInfo.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pageInfo.pageIndex}
              />
            </div>
          </CardFooter>
        </Card>
      </section>
      {/* <!-- /.content --> */}
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Chi tiết</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="name" sm={3}>
                Tên
              </Label>
              <Col sm={9}>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={inputModal.name}
                  onChange={(e) => handleChange(e)}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="phone" sm={3}>
                Điện thoại
              </Label>
              <Col sm={9}>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="Nhập phone"
                  type="text"
                  value={inputModal.phone}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={3}>
                Email
              </Label>
              <Col sm={9}>
                <Input
                  id="email"
                  name="email"
                  placeholder="Nhập Email"
                  type="text"
                  value={inputModal.email}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="content" sm={3}>
                Nội dung
              </Label>
              <Col sm={9}>
                <Input
                  id="content"
                  name="content"
                  placeholder="Nhập nội dung"
                  type="textarea"
                  value={inputModal.content}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Đóng
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Feedback;
