import React from 'react';

const ControlSidebar = () => {
  return (
    // <!-- Control Sidebar -->
    <aside className="control-sidebar control-sidebar-dark">
      {/* <!-- Control sidebar content goes here --> */}
    </aside>
    //  <!-- /.control-sidebar -->
  );
};

export default ControlSidebar;
