import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Common, Constant, Agent } from '../../utils';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Topbar from 'topbar';

const DEFAULT_INPUT = {
  userName: Common.isDevENV() ? 'admin' : '',
  password: Common.isDevENV() ? 'Nht@2706' : '',
};
const Login = () => {
  const [inputData, setInputData] = useState(DEFAULT_INPUT);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (Common.isAuthenticated()) {
      window.location.href = '/';
    }
    document.body.className = 'hold-transition login-page';
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    for (var key in inputData) {
      if (inputData[key] === '') {
        toast.warning('Không được để trống !');
        return;
      }
    }
    setLoading(true);
    Topbar.show();
    Agent.Auth.login(inputData)
      .then((res) => {
        toast.success('Đăng nhập thành công !');
        Cookies.set(Constant.TOKEN, res.token);
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Đăng nhập thất bại !');
      })
      .finally(() => {
        Topbar.hide();
        setLoading(false);
      });
  };
  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <h1>
            <b>CMS</b>
          </h1>
        </div>
        <div className="card-body">
          <p className="login-box-msg">Đăng nhập hệ thống</p>

          <form>
            <div className="input-group mb-3">
              <input
                name="userName"
                type="userName"
                className="form-control"
                placeholder="Tên người dùng"
                value={inputData.userName}
                onChange={(e) => handleChange(e)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={inputData.password}
                onChange={(e) => handleChange(e)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {loading ? (
                  <button className="btn btn-primary btn-block" disabled>
                    Đang xử lý ...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block"
                    onClick={handleSubmit}
                  >
                    Đăng nhập
                  </button>
                )}
              </div>
            </div>
          </form>

          <p className="mb-1"></p>
          {/* <p className="mb-0">
            <Link to="/register" className="text-center">
              Đăng ký tài khoản mới
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
