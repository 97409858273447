import Cookies from 'js-cookie';
import { Constant } from '.';
export function ToShortDateStr(value) {
  if (value !== null && value !== undefined) {
    let dt = new Date(value);
    return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
  }
  return '';
}

export function ToShortDateTimeStr(value) {
  if (value !== null && value !== undefined) {
    let dt = new Date(value);
    return `${dt.getDate()}/${
      dt.getMonth() + 1
    }/${dt.getFullYear()} - ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
  }
  return '';
}

export function isAuthenticated() {
  return Cookies.get(Constant.TOKEN);
}

export function isDevENV() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}
