import React from 'react';
import NavMenu from '../navmenu/NavMenu';
import Sidebar from '../sidebar/Sidebar';
import ControlSidebar from '../controlSidebar/ControlSidebar';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <NavMenu />
      <Sidebar />
      <div className="content-wrapper">{children}</div>
      <Footer />
      <ControlSidebar />
    </div>
  );
};

export default Layout;
