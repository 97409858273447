import React, { useEffect } from 'react';
import './Home.css';

const Home = () => {
  useEffect(() => {
    document.body.className = 'hold-transition sidebar-mini';
  }, []);
  return <></>;
};

export default Home;
