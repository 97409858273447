import { Common } from '.';

export const SERVER_URL = Common.isDevENV()
  ? 'https://localhost:8080/api/'
  : 'https://cms.panelvietphap.com.vn/api/';
export const PORTAL_SERVER_URL = Common.isDevENV()
  ? 'https://localhost:5001/api/'
  : 'https://panelvietphap.com.vn/api/';

export const TOKEN = 'cms_token';
export const PRODUCT_URL = 'product';
export const PRODUCT_CATEGORY_URL = 'productcategories';
export const MEDIA_URL = 'media';
export const SLIDE_URL = 'slide';
export const FEEDBACK_URL = 'feedback';
export const TAG_URL = 'tag';
export const POST_URL = 'post';
export const POSTCATEGORY_URL = 'postcategory';
export const FOOTER_URL = 'footer';
export const ABOUT_URL = 'about';
export const VISITORSTATISTIC_URL = 'visitorstatistic';
export const GALLERY_URL = 'gallery';
export const LOGIN_URL = 'account/login';
export const REGISTER_URL = 'account/register';
export const REFRESH_TOKEN_URL = 'account/refreshtoken';
export const ADD = 0;
export const EDIT = 1;
export const DETAIL = 2;
export const DELETE = 3;

export const DEFAULT_PAGE_INFO = {
  totalItems: 0,
  pageSize: 10,
  pageIndex: 0,
  pageCount: 1,
};
