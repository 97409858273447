import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import './ActionButton.css';
import { Constant } from '../../utils';

const ActionButton = ({ item, setMode, canEdit = true, canDelete = true }) => {
  const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

  return (
    <div className="action-button">
      <Button
        id="btnInfo"
        color="success"
        onClick={() => {
          setMode(Constant.DETAIL, item);
        }}
      >
        <i className="nav-icon fas fa-info"></i>
      </Button>
      {canEdit ? (
        <>
          <Button
            id="btnEdit"
            color="primary"
            onClick={() => {
              setMode(Constant.EDIT, item);
            }}
          >
            <i className="nav-icon fas fa-pen"></i>
          </Button>
          <Tooltip
            placement="top"
            target="btnEdit"
            toggle={() => setEditTooltipOpen(!editTooltipOpen)}
            isOpen={editTooltipOpen}
          >
            Sửa
          </Tooltip>
        </>
      ) : null}
      {canDelete ? (
        <>
          <Button
            id="btnDelete"
            color="danger"
            onClick={() => {
              setMode(Constant.DELETE, item);
            }}
          >
            <i className="nav-icon fas fa-trash"></i>
          </Button>
          <Tooltip
            placement="top"
            target="btnInfo"
            toggle={() => setInfoTooltipOpen(!infoTooltipOpen)}
            isOpen={infoTooltipOpen}
          >
            Chi tiết
          </Tooltip>
          <Tooltip
            placement="top"
            target="btnDelete"
            toggle={() => setDeleteTooltipOpen(!deleteTooltipOpen)}
            isOpen={deleteTooltipOpen}
          >
            Xóa
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default ActionButton;
