import axios from 'axios';
import { Constant } from '.';
import Cookies from 'js-cookie';

axios.defaults.baseURL = Constant.SERVER_URL;

axios.defaults.withCredentials = true;

const responseBody = (response) => response.data;

axios.interceptors.request.use((config) => {
  const token = Cookies.get(Constant.TOKEN);
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
function createFormData(item) {
  let formData = new FormData();
  for (const key in item) {
    formData.append(key, item[key]);
  }
  return formData;
}

const requests = {
  get: (url, params) => axios.get(url, { params }).then(responseBody),
  getWithoutCredentials: (url, params) =>
    axios.get(url, { params, withCredentials: false }).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
  postForm: (url, data) =>
    axios
      .post(url, data, {
        headers: { 'Content-type': 'multipart/form-data' },
        withCredentials: false,
      })
      .then(responseBody),
  putForm: (url, data) =>
    axios
      .put(url, data, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody),
};

const Product = {
  getAll: () => requests.get(Constant.PRODUCT_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.PRODUCT_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.PRODUCT_URL, item),
  update: (id, item) => requests.put(Constant.PRODUCT_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.PRODUCT_URL + `/${id}`),
};
const Media = {
  getAll: () => requests.get(Constant.MEDIA_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.MEDIA_URL + `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.MEDIA_URL, item),
  upload: (item) =>
    requests.postForm(
      Constant.PORTAL_SERVER_URL + Constant.MEDIA_URL,
      createFormData(item)
    ),
  remove: (id) => requests.delete(Constant.MEDIA_URL + `/${id}`),
  removeFile: (fileName) =>
    requests.getWithoutCredentials(
      Constant.PORTAL_SERVER_URL + 'media/' + fileName
    ),
};

const Slide = {
  getAll: () => requests.get(Constant.SLIDE_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.SLIDE_URL + `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.SLIDE_URL, item),
  update: (id, item) => requests.put(Constant.SLIDE_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.SLIDE_URL + `/${id}`),
};

const Gallery = {
  getAll: () => requests.get(Constant.GALLERY_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.GALLERY_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.GALLERY_URL, item),
  update: (id, item) => requests.put(Constant.GALLERY_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.GALLERY_URL + `/${id}`),
};

const Auth = {
  login: (item) =>
    axios
      .post(Constant.LOGIN_URL, item, { withCredentials: false })
      .then(responseBody),
  register: (item) =>
    axios
      .post(Constant.REGISTER_URL, item, { withCredentials: false })
      .then(responseBody),
  refreshToken: (item) =>
    axios
      .post(Constant.REFRESH_TOKEN_URL, item, { withCredentials: false })
      .then(responseBody),
};

const Feedback = {
  getAll: () => requests.get(Constant.FEEDBACK_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.FEEDBACK_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.FEEDBACK_URL, item),
  update: (id, item) => requests.put(Constant.FEEDBACK_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.FEEDBACK_URL + `/${id}`),
};

const Tag = {
  getAll: () => requests.get(Constant.TAG_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.TAG_URL + `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.TAG_URL, item),
  update: (id, item) => requests.put(Constant.TAG_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.TAG_URL + `/${id}`),
};

const Post = {
  getAll: () => requests.get(Constant.POST_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.POST_URL + `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.POST_URL, item),
  update: (id, item) => requests.put(Constant.POST_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.POST_URL + `/${id}`),
};

const PostCategory = {
  getAll: () => requests.get(Constant.POSTCATEGORY_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.POSTCATEGORY_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.POSTCATEGORY_URL, item),
  update: (id, item) =>
    requests.put(Constant.POSTCATEGORY_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.POSTCATEGORY_URL + `/${id}`),
};

const Footer = {
  getAll: () => requests.get(Constant.FOOTER_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.FOOTER_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.FOOTER_URL, item),
  update: (id, item) => requests.put(Constant.FOOTER_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.FOOTER_URL + `/${id}`),
};

const About = {
  getAll: () => requests.get(Constant.ABOUT_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.ABOUT_URL + `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.ABOUT_URL, item),
  update: (id, item) => requests.put(Constant.ABOUT_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.ABOUT_URL + `/${id}`),
};

const VisitorStatistic = {
  getAll: () => requests.get(Constant.VISITORSTATISTIC_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.VISITORSTATISTIC_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.VISITORSTATISTIC_URL, item),
  update: (id, item) =>
    requests.put(Constant.VISITORSTATISTIC_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.VISITORSTATISTIC_URL + `/${id}`),
};

const ProductCategory = {
  getAll: () => requests.get(Constant.PRODUCT_CATEGORY_URL),
  getAllPaging: (pageIndex, pageSize) =>
    requests.get(
      Constant.PRODUCT_CATEGORY_URL +
        `/paging?pageindex=${pageIndex}&pageSize=${pageSize}`
    ),
  add: (item) => requests.post(Constant.PRODUCT_CATEGORY_URL, item),
  update: (id, item) =>
    requests.put(Constant.PRODUCT_CATEGORY_URL + `/${id}`, item),
  remove: (id) => requests.delete(Constant.PRODUCT_CATEGORY_URL + `/${id}`),
};

const agent = {
  Product,
  ProductCategory,
  Media,
  Slide,
  Gallery,
  Auth,
  Tag,
  Post,
  PostCategory,
  Footer,
  About,
  VisitorStatistic,
  Feedback,
};

export default agent;
