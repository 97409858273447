import {
  Home,
  Product,
  Media,
  Slide,
  Gallery,
  Feedback,
  Tag,
  ProductCategory,
  Footer,
  About,
  VisitorStatistic,
} from './pages';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: '/product',
    element: <Product />,
  },
  {
    path: '/media',
    element: <Media />,
  },
  {
    path: '/slide',
    element: <Slide />,
  },
  {
    path: '/gallery',
    element: <Gallery />,
  },
  {
    path: '/feedback',
    element: <Feedback />,
  },
  {
    path: '/tag',
    element: <Tag />,
  },
  {
    path: '/productCategory',
    element: <ProductCategory />,
  },
  {
    path: '/footer',
    element: <Footer />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/visitorStatistic',
    element: <VisitorStatistic />,
  },
];

export default AppRoutes;
