/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { ActionButton } from '../../components';
import { Agent, Common, Constant } from '../../utils';
import './Product.css';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { Gallery } from 'react-grid-gallery';
import Topbar from 'topbar';

const DEFAULT_INPUT = {
  name: '',
  description: '',
  image: '',
  isFeatured: false,
  seoAlias: '',
  seoDescription: '',
  seoTitle: '',
  categoryId: '',
};

const Product = () => {
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [selectParent, setSelectParent] = useState([]);
  const [inputModal, setInputModal] = useState(DEFAULT_INPUT);
  const [modeModal, setModeModal] = useState(Constant.ADD);
  const [initEditorValue, setInitEditorValue] = useState('');
  const [pageInfo, setPageInfo] = useState(Constant.DEFAULT_PAGE_INFO);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    let refreshImages = images.map((image) => ({
      ...image,
      isSelected: false,
    }));
    setImages(refreshImages);
  };
  const [nestedModal, setNestedModal] = useState(false);
  const editorRef = useRef(null);
  useEffect(() => {
    loadData();
    loadImages();
    loadParrentData();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageInfo.pageIndex]);

  const loadImages = () => {
    Agent.Media.getAll()
      .then((res) => {
        let imageData = [];
        res.forEach((item) => {
          imageData.push({
            src: item.url,
            isSelected: false,
            caption: item.name,
            customOverlay: (
              <div className="custom-overlay__caption">
                <div>{item.name}</div>
              </div>
            ),
          });
        });
        setImages(imageData);
      })
      .catch((err) => console.log(err));
  };
  const loadData = () => {
    Topbar.show();
    Agent.Product.getAllPaging(pageInfo.pageIndex, pageInfo.pageSize)
      .then((res) => {
        setData(res.items);
        setPageInfo((prevState) => ({
          ...prevState,
          totalItems: res.totalItems,
          pageCount: res.pageCount,
        }));
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => Topbar.hide());
  };

  const loadParrentData = () => {
    Agent.ProductCategory.getAll()
      .then((res) => {
        let arr = res.map(({ id, name }) => ({ value: id, name }));
        arr.unshift({ value: null, name: 'Không có' });
        setSelectParent(arr);
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      });
  };

  const modifyProduct = () => {
    Topbar.show();
    if (modeModal === Constant.ADD) {
      Agent.Product.add(inputModal)
        .then((res) => {
          loadData();
          toggle();
          toast.success('Thêm sản phẩm mới thành công');
        })
        .catch((err) => {
          toast.error('Đã có lỗi xảy ra: ' + err);
          console.log(err);
        })
        .finally(() => Topbar.hide());
    } else if (modeModal === Constant.EDIT) {
      Agent.Product.update(inputModal.id, inputModal)
        .then((res) => {
          loadData();
          toggle();
          toast.success('Cập nhật sản phẩm thành công');
        })
        .catch((err) => {
          toast.error('Đã có lỗi xảy ra: ' + err);
          console.log(err);
        })
        .finally(() => Topbar.hide());
    }
  };

  const handleSetMode = (mode, item) => {
    setInputModal(item);
    if (mode === Constant.DELETE) {
      toggleConfirm();
    } else {
      setInitEditorValue(item.description);
      setModeModal(mode);
      toggle();
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'isFeatured') {
      setInputModal((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setInputModal((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleUpdate = (value, editor) => {
    if (editorRef.current) {
      let content = editor.getContent({ format: 'html' });
      setInputModal((prevState) => ({
        ...prevState,
        description: content,
      }));
    }
  };

  const handleDelete = () => {
    Topbar.show();
    Agent.Product.remove(inputModal.id)
      .then((res) => {
        toast.success('Xóa sản phẩm thành công !');
        loadData();
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => {
        toggleConfirm();
      })
      .finally(() => Topbar.hide());
  };

  const handlePageChange = (e) => {
    console.log(e);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    setPageInfo((prevState) => ({
      ...prevState,
      pageIndex: e.selected,
    }));
  };

  const handleSelect = (index) => {
    const nextImages = images.map((image, i) =>
      //i === index ? { ...image, isSelected: !image.isSelected } : image
      {
        if (i === index || image.isSelected) {
          return { ...image, isSelected: !image.isSelected };
        }
        return image;
      }
    );
    setImages(nextImages);
    //toggleNested();
  };
  const handleSelectedImage = () => {
    let image = images.find((x) => x.isSelected);
    setInputModal((prevState) => ({
      ...prevState,
      image: image.src,
    }));
    toggleNested();
  };
  const handleCopyLink = () => {
    let image = images.find((x) => x.isSelected);
    navigator.clipboard.writeText(image.src);
    toast.success('Copy link ảnh thành công !');
    toggleNested();
  };
  return (
    <>
      {/* // <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Sản phẩm</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Trang chủ</Link>
                </li>
                <li className="breadcrumb-item active">Sản phẩm</li>
              </ol>
            </div>
          </div>
        </div>
        {/* <!-- /.container-fluid --> */}
      </section>

      {/* // <!-- Main content --> */}
      <section className="content">
        <Card className="my-2">
          <CardHeader>
            <Button
              color="success"
              onClick={() => {
                setInputModal(DEFAULT_INPUT);
                setModeModal(Constant.ADD);
                setInitEditorValue('');
                toggle();
              }}
            >
              <i
                className="nav-icon fas fa-plus"
                style={{ paddingRight: 10 }}
              ></i>
              Thêm mới
            </Button>
          </CardHeader>
          <CardBody>
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '30%' }}>Tên</th>
                  <th style={{ width: '15%', textAlign: 'center' }}>
                    Trạng thái
                  </th>
                  <th style={{ width: '20%' }}>Ngày tạo</th>
                  <th style={{ width: '20%' }}>Ngày cập nhật</th>
                  <th style={{ width: '10%', textAlign: 'center' }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1 + pageInfo.pageIndex * 10}</th>
                      <td>{item.name}</td>
                      <td
                        style={{ verticalAlign: 'middle', textAlign: 'center' }}
                      >
                        {item.isFeatured ? (
                          <span className="badge badge-success">Hiển thị</span>
                        ) : (
                          <span className="badge badge-warning">Ẩn</span>
                        )}
                      </td>
                      <td>{Common.ToShortDateStr(item.createdAt)}</td>
                      <td>{Common.ToShortDateStr(item.updatedAt)}</td>
                      <td>
                        <ActionButton
                          canEdit={true}
                          item={item}
                          setMode={handleSetMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <div style={{ float: 'right' }}>
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageInfo.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pageInfo.pageIndex}
              />
            </div>
          </CardFooter>
        </Card>
      </section>
      {/* <!-- /.content --> */}
      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>
          {modeModal === Constant.ADD
            ? 'Thêm mới sản phẩm'
            : modeModal === Constant.DETAIL
            ? 'Chi tiết sản phẩm'
            : 'Cập nhật sản phẩm'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="name" sm={3}>
                Tên sản phẩm
              </Label>
              <Col sm={9}>
                <Input
                  id="name"
                  name="name"
                  placeholder="Nhập tên sản phẩm"
                  type="text"
                  value={inputModal.name}
                  onChange={(e) => handleChange(e)}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="image" sm={3}>
                Ảnh sản phẩm
              </Label>
              <Col sm={6}>
                <Input
                  id="image"
                  name="image"
                  placeholder="Nhập URL ảnh sản phẩm"
                  type="text"
                  value={inputModal.image}
                  onChange={(e) => handleChange(e)}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
              <Col sm={3}>
                <Button color="primary" onClick={toggleNested}>
                  <i className="nav-icon fas fa-image"></i> &nbsp; Thư viện ảnh
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="categoryId" sm={3}>
                Danh mục
              </Label>
              <Col sm={9}>
                <Input
                  id="categoryId"
                  name="categoryId"
                  type="select"
                  value={inputModal.categoryId}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                >
                  {selectParent.map((item, index) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="description" sm={3}>
                Mô tả
              </Label>
              <Col sm={9}>
                <Editor
                  apiKey="qu5u8a7e86zgxav1bo0k8vi3j5ayaua87icu3k35d51lz5hg"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={handleUpdate}
                  initialValue={initEditorValue}
                  disabled={modeModal === Constant.DETAIL}
                  init={{
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [],
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="seoTitle" sm={3}>
                Seo Title
              </Label>
              <Col sm={9}>
                <Input
                  id="seoTitle"
                  name="seoTitle"
                  placeholder="Nhập Seo title"
                  type="text"
                  value={inputModal.seoTitle}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="seoDescription" sm={3}>
                Seo description
              </Label>
              <Col sm={9}>
                <Input
                  id="seoDescription"
                  name="seoDescription"
                  placeholder="Nhập Seo description"
                  type="text"
                  value={inputModal.seoDescription}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="seoAlias" sm={3}>
                Seo Alias
              </Label>
              <Col sm={9}>
                <Input
                  id="seoAlias"
                  name="seoAlias"
                  placeholder="Nhập Seo Alias"
                  type="text"
                  value={inputModal.seoAlias}
                  onChange={handleChange}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="isFeatured" sm={3}>
                Hiển thị trang chủ
              </Label>
              <Col
                sm={{
                  size: 9,
                }}
              >
                <FormGroup>
                  <Input
                    id="isFeatured"
                    type="checkbox"
                    name="isFeatured"
                    defaultChecked={false}
                    checked={inputModal.isFeatured}
                    onChange={handleChange}
                    disabled={modeModal === Constant.DETAIL}
                  />{' '}
                </FormGroup>
              </Col>
            </FormGroup>
          </Form>
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            size="lg"
            backdrop="static"
          >
            <ModalHeader toggle={toggleNested}>Thư viện ảnh</ModalHeader>
            <ModalBody>
              <Gallery images={images} onSelect={handleSelect} />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={handleSelectedImage}
                disabled={images.filter((x) => x.isSelected).length === 0}
              >
                <i className="nav-icon fas fa-check"></i>&nbsp; Chọn
              </Button>
              <Button
                color="primary"
                onClick={handleCopyLink}
                disabled={images.filter((x) => x.isSelected).length === 0}
              >
                <i className="nav-icon fas fa-link"></i>&nbsp; Sao chép URL
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
          {modeModal === Constant.DETAIL ? (
            <Button color="secondary" onClick={toggle}>
              Đóng
            </Button>
          ) : (
            <>
              {' '}
              <Button color="primary" onClick={modifyProduct}>
                Lưu
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Hủy
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalConfirm} toggle={toggleConfirm} backdrop="static">
        <ModalHeader toggle={toggleConfirm}>Xác nhận</ModalHeader>
        <ModalBody>
          <h3>Bạn có muốn xóa sản phẩm không ?</h3>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleDelete}>
            Đồng ý
          </Button>
          <Button color="secondary" onClick={toggleConfirm}>
            Hủy
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Product;
