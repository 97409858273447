/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Col,
} from 'reactstrap';
import { ActionButton } from '../../components';
import { Agent, Common, Constant } from '../../utils';
import './Media.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Topbar from 'topbar';

const DEFAULT_INPUT = {
  type: 'image',
  blob: null,
  name: '',
  url: '',
  description: '',
};
const Media = () => {
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [inputModal, setInputModal] = useState(DEFAULT_INPUT);
  const [modeModal, setModeModal] = useState(Constant.ADD);
  const [pageInfo, setPageInfo] = useState(Constant.DEFAULT_PAGE_INFO);
  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageInfo.pageIndex]);

  const loadData = () => {
    Topbar.show();
    Agent.Media.getAllPaging(pageInfo.pageIndex, pageInfo.pageSize)
      .then((res) => {
        setData(res.items);
        setPageInfo((prevState) => ({
          ...prevState,
          totalItems: res.totalItems,
          pageCount: res.pageCount,
        }));
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => Topbar.hide());
  };
  const handleSetMode = (mode, item) => {
    setInputModal(item);
    if (mode === Constant.DELETE) {
      toggleConfirm();
    } else {
      setModeModal(mode);
      toggle();
    }
  };

  const handlePageChange = (e) => {
    console.log(e);
    // TODO Only change displayed selected page
    // when its content is loaded in useEffect.
    setPageInfo((prevState) => ({
      ...prevState,
      pageIndex: e.selected,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setInputModal((prevState) => ({
        ...prevState,
        blob: e.target.files[0],
        description: e.target.files[0].name,
      }));
    }
  };

  const handleAddMedia = async () => {
    Topbar.show();
    let uploadRes = await Agent.Media.upload({ blob: inputModal.blob });
    inputModal.url = uploadRes.fileUrl;
    inputModal.name = uploadRes.fileName;
    Agent.Media.add(inputModal)
      .then((res) => {
        loadData();
        toggle();
        toast.success('Thêm nội dung mới thành công');
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => Topbar.hide());
  };

  const handleDelete = async () => {
    Topbar.show();
    await Agent.Media.removeFile(inputModal.name);
    Agent.Media.remove(inputModal.id)
      .then((res) => {
        toast.success('Xóa nội dung thành công !');
        loadData();
      })
      .catch((err) => {
        toast.error('Đã có lỗi xảy ra: ' + err);
        console.log(err);
      })
      .finally(() => {
        toggleConfirm();
        Topbar.hide();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputModal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      {/* // <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Media</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Trang chủ</Link>
                </li>
                <li className="breadcrumb-item active">Media</li>
              </ol>
            </div>
          </div>
        </div>
        {/* <!-- /.container-fluid --> */}
      </section>

      {/* // <!-- Main content --> */}
      <section className="content">
        <Card className="my-2">
          <CardHeader>
            <Button
              color="success"
              onClick={() => {
                setInputModal(DEFAULT_INPUT);
                setModeModal(Constant.ADD);
                toggle();
              }}
            >
              <i
                className="nav-icon fas fa-plus"
                style={{ paddingRight: 10 }}
              ></i>
              Thêm mới
            </Button>
          </CardHeader>
          <CardBody>
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>#</th>
                  <th style={{ width: '15%' }}>Nội dung</th>
                  <th style={{ width: '15%' }}>Loại</th>
                  <th style={{ width: '15%' }}>Mô tả</th>
                  <th style={{ width: '20%' }}>Ngày tạo</th>
                  <th style={{ width: '20%' }}>Ngày cập nhật</th>
                  <th style={{ width: '10%', textAlign: 'center' }}>
                    Hành động
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1 + pageInfo.pageIndex * 10}</th>
                      <td>
                        <img
                          src={item.url}
                          alt={item.description}
                          className="img-thumbnail thumbnail-list"
                        />
                      </td>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>{Common.ToShortDateStr(item.createdAt)}</td>
                      <td>{Common.ToShortDateStr(item.updatedAt)}</td>
                      <td>
                        <ActionButton
                          canEdit={false}
                          item={item}
                          setMode={handleSetMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <div style={{ float: 'right' }}>
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageInfo.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pageInfo.pageIndex}
              />
            </div>
          </CardFooter>
        </Card>
      </section>
      {/* <!-- /.content --> */}

      <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>
          {modeModal === Constant.ADD
            ? 'Thêm mới nội dung'
            : 'Chi tiết nội dung'}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="type" sm={3}>
                Loại
              </Label>
              <Col sm={9}>
                <Input
                  id="type"
                  name="type"
                  type="select"
                  value={inputModal.type}
                  onChange={(e) => handleChange(e)}
                >
                  <option value={'image'}>Ảnh</option>
                  <option value={'video'}>Video</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="description" sm={3}>
                Mô tả
              </Label>
              <Col sm={9}>
                <Input
                  id="description"
                  name="description"
                  placeholder="Nhập mô tả nội dung"
                  type="text"
                  value={inputModal.description}
                  onChange={(e) => handleChange(e)}
                  disabled={modeModal === Constant.DETAIL}
                />
              </Col>
            </FormGroup>
            {modeModal === Constant.DETAIL ? (
              <FormGroup row>
                <Label for="url" sm={3}>
                  Đường dẫn
                </Label>
                <Col sm={9}>
                  <Input
                    id="url"
                    name="url"
                    type="text"
                    value={inputModal.url}
                    readOnly
                  />
                </Col>
              </FormGroup>
            ) : null}
            <FormGroup row>
              {modeModal === Constant.DETAIL ? (
                <>
                  <Label for="image" sm={3}>
                    Nội dung
                  </Label>
                  <Col sm={9}>
                    <img
                      src={inputModal.url}
                      alt={inputModal.description}
                      className="image-detail"
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Label for="image" sm={3}>
                    File
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="image"
                      name="image"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Col>
                </>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {modeModal === Constant.DETAIL ? (
            <Button color="secondary" onClick={toggle}>
              Đóng
            </Button>
          ) : (
            <>
              {' '}
              <Button color="primary" onClick={handleAddMedia}>
                Lưu
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Hủy
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalConfirm} toggle={toggleConfirm} backdrop="static">
        <ModalHeader toggle={toggleConfirm}>Xác nhận</ModalHeader>
        <ModalBody>
          <h3>Bạn có muốn xóa nội dung này không ?</h3>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleDelete}>
            Đồng ý
          </Button>
          <Button color="secondary" onClick={toggleConfirm}>
            Hủy
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Media;
